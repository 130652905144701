<template>
    <div class="PurchasesDetail">
        <layout :header="'MY ' + $route.params.type" @goback="$router.push('/Purchases')" :bodyStrong="false">
            <template v-slot:content>
                <v-col cols="12" xs="12" sm="10" md="8" lg="6">
                    <v-row no-gutters justify="center">
                        <v-col cols="12" class="content">
                            <v-row justify="center">
                                <v-col cols="12" class="text-center">
                                    <h3>
                                        {{title}}
                                    </h3>
                                </v-col>
                                <v-col cols="12">
                                    <div class="pa-4 pb-2 card-item" elevation="0">
                                        <v-img :src="couponsCodeById.coupon_list_id.image_url" alt="">
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height ma-0" align="center" justify="center">
                                                    <v-progress-circular indeterminate color="#463729">
                                                    </v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                        <div class="pt-3">
                                            <div style="font-size:19px;font-weight:800;">
                                                <!-- {{$i18n.locale == 'th' ? couponsCodeById.coupon_id.name || '': couponsCodeById.coupon_id.name_en || ''}} -->
                                                {{couponsCodeById.coupon_list_id.name}}
                                            </div>
                                        </div>
                                        <div style="font-size:12px;font-weight:800;text-indent;12px;color:#a5a5a5;" v-if="couponsCodeById.expire_at != undefined">
                                            {{$t('expire')}} {{DateTime(couponsCodeById.expire_at.iso) || ''}}
                                        </div>
                                        <div>
                                            <div style="font-size:14px;font-weight:800;text-indent;12px;color:#a5a5a5;" class="mt-1">
                                                {{$i18n.locale == 'th' ? couponsCodeById.coupon_id.detail || '': couponsCodeById.coupon_id.detail_en || ''}}
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5" justify="center">
                                <v-col cols="6" xs="6" class="px-2">
                                    <v-btn rounded large block color="#9D662C" @click="$router.push('/Purchases')">
                                        {{$i18n.locale == 'th' ? 'ยกเลิก': 'No'}}
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" xs="6" class="px-2">
                                    <v-btn rounded large block color="#463729" @click="usedCoupon">
                                        {{$i18n.locale == 'th' ? 'ตกลง': 'Yes'}}</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </layout>
        <loading v-show="loadingState"></loading>
        <alert type="error" :header="$t('failed')"
            :title="$i18n.locale == 'th' ? 'ไม่สามารถใช้คูปองได้<br>กรุณาลองใหม่อีกครั้ง': 'Please try again.'"
            v-show="showAlertError" @done="showAlertError = false;"></alert>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1"
    import Loading from "../../components/loading"
    import Alert from "../../components/alert";
    export default {
        name: "PurchasesDetail",
        components: {
            'layout': Layout,
            'loading': Loading,
            'alert': Alert
        },
        data: () => ({
            loadingState: false,
            showAlertError: false,
        }),
        computed: {
            couponsCodeById() {
                return this.$store.getters.coupon_codesById(this.$route.params.id);
            },
            title() {
                let txt = '';
                if (this.$i18n.locale == 'en') {
                    txt = this.$t('PurchasesDetailTitle') + this.$route.params.type + ' ?';
                } else {
                    if (this.$route.params.type == 'Coupon') {
                        txt = this.$t('PurchasesDetailTitle') + 'คูปองนี้ ?';
                    } else {
                        txt = this.$t('PurchasesDetailTitle') + 'รางวัลนี้ ?';
                    }

                }
                return txt;
            }
        },
        methods: {
            async usedCoupon() {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params = {
                        coupon_code_id: this.couponsCodeById.objectId
                    }
                    let result = await this.$axios.post('/use_code', params, config);
                    console.log(result);
                    if (result.data.success == true) {
                        this.$router.push('/Purchases/Used/' + this.$route.params.type + '/' + this.couponsCodeById
                            .objectId);
                    } else {
                        throw 400
                    }
                } catch (err) {
                    this.showAlertError = true;
                }
                this.loadingState = false;
            }
        }
    }
</script>

<style lang="scss">
    .PurchasesDetail {
        height: 100%;

        .content {
            padding: 0px 16px 55px 16px;
            min-height: 100%;

            .card-item {
                border-radius: 8px;
                border: 1px solid #D3D1C3;

                .v-image {
                    border-radius: 8px !important;
                }
            }
        }
    }
</style>